import { Globals } from "../Globals";

declare var THREE;
declare var WEBGL;

export class Viewer{

    viewWidth = 700;
    viewHeight = 420;
    controls;
    camera;
    scene;
    renderer;
    container;
    stats;
    clock;
    elf;
    pointlight;
    pointlight2;
    pointLightHelper;

    campos_x;
    campos_y;
    campos_z;
    lookpos_x;
    lookpos_y;
    lookpos_z;

    orbitControls() {
        // add the controls 
        this.controls = new THREE.TrackballControls(this.camera, this.renderer.domElement);
        this.controls.rotateSpeed = 1.5;
        this.controls.zoomSpeed = 1.2;
        this.controls.panSpeed = 0.3;
        //this.controls.noPan = true;
        //this.controls.noPan = false;
        this.controls.minDistance = 5;
        this.controls.maxDistance = 200;

    }


    loadgl(daeFileContent, camerapos){
        if (WEBGL.isWebGLAvailable() === false) {
            document.body.appendChild(WEBGL.getWebGLErrorMessage());
        }
        
        this.campos_x = camerapos.campos_x;
        this.campos_y = camerapos.campos_y;
        this.campos_z = camerapos.campos_z;
        this.lookpos_x = camerapos.lookpos_x;
        this.lookpos_y = camerapos.lookpos_y;
        this.lookpos_z = camerapos.lookpos_z;
        
        this.init(daeFileContent);
        this.animate();
        
        // function onWindowResize() {
    
        //     this.camera.aspect = this.viewWidth / this.viewHeight;
        //     this.camera.updateProjectionMatrix();
        //     this.renderer.setSize(this.viewWidth, this.viewHeight);
        // }
    
    
    }

    init(daeFileContent) {
        this.renderer = new THREE.WebGLRenderer( { antialias: true } );
        //this.renderer.shadowMap.enabled = true;
        //this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap

        let material = new THREE.MeshPhongMaterial({ ambient: 0x050505, color: 0x0033ff, specular: 0x000000, shininess: 0 });
        material.side = 1;
        material.flatShading = true;
        this.container = document.getElementById('container3d');
        this.container.innerHTML = '';
        this.camera = new THREE.PerspectiveCamera(45, this.viewWidth / this.viewHeight, 0.1, 2000);
        //this.camera.position.set(0, 0, 15);
        
        if (this.campos_x != null && this.campos_y != null && this.campos_z != null){
            this.camera.position.x = this.campos_x; this.camera.position.y = this.campos_y; this.camera.position.z = this.campos_z;
        } else {
            this.camera.position.x = 7.713442844794137; this.camera.position.y = 7.03148885487495; this.camera.position.z = 9.40818637102886;
        }

        this.camera.up = new THREE.Vector3(0,1,0);
        //this.camera.lookAt(new THREE.Vector3(0,0,0));
        if (this.lookpos_x != null && this.lookpos_y != null && this.lookpos_z != null){
            this.camera.lookAt(new THREE.Vector3(this.lookpos_x, this.lookpos_y, this.lookpos_z));
        } else {
            this.camera.lookAt(0, 0, 0);
        }
		//this.camera.up.x = -0.592466865166082; this.camera.up.y = 0.4110275783078145; this.camera.up.z = 0.6928487162078913;
        //this.camera.rotation.x = -0.4788754489324883;
        //this.camera.rotation.y = 0.5055475129234114;
        //this.camera.rotation.z = 0.2460668893968353;
        //camera.up.y = -1;
        this.scene = new THREE.Scene();
        this.clock = new THREE.Clock();


        // loading manager

        var loadingManager = new THREE.LoadingManager(() => {

            for (let i = 0; i < this.elf.children.length; i++) {
                const element = this.elf.children[i];
                let back = element.name.split('_')[element.name.split('_').length - 1] == 'b';
                if (element.material) {
                    element.material.alphaTest = 0.5;

                }
                if (back) {
                    if (element.material) {
                        element.material.side = THREE.BackSide;
                        //element.material.specular = new THREE.Color( 0x000000 );
                        //element.material.color = new THREE.Color( 0x00ff00 );
                    }
                    //if (element.material) element.material = material;
                } else {
                    if (element.material) {
                        element.material.specular = new THREE.Color( 0x000000 );
                        element.material.color = new THREE.Color( 0xeeeeee );
                    }
                    //if (element.material) element.material.side = 0;
                }
            }

            this.scene.add(this.elf);

        });


        // collada
        var loader = new THREE.ColladaLoader(loadingManager);
        //				loader.load( './models/collada/elf/elf.dae', function ( collada ) {
        // loader.load( './t_tex.dae', function ( collada ) {
        //console.log(daeFileContent);
        loader.loadAsText(daeFileContent, Globals.config.KOROBKI_UPLOAD_PATH ,  (collada) =>{
            this.elf = collada.scene;
        });
        this.elf.rotation.x = 0;
        this.elf.rotation.z = Math.PI;

        this.elf.castShadow = true;
        this.elf.receiveShadow = true;

        for (var child of this.elf.children) {
            if (child.material) {
                child.material.flatShading = true;
                //child.material.map = null;
                child.material.shininess = 0;
                child.transparent = false;
                child.castShadow = true;
                child.receiveShadow = true;
                child.material.clipShadows = true;
                child.material.clipIntersection = true;
                child.material.fog = false;
                child.material.transparent = false;
            }
        }
        //this.elf.children.map(c => {c.})

        //
        //var ambientLight = new THREE.AmbientLight(0x0fffff, 0.3);
        //this.scene.add( ambientLight );
        //var directionalLight = new THREE.DirectionalLight(0xff0fff, 0.2);
        //directionalLight.position.set(100, 100, 100).normalize();
        //this.scene.add( directionalLight );
        //this.camera.add( directionalLight );

        this.pointlight = new THREE.SpotLight( 0xffffff, 0.9 );
        //this.pointlight.castShadow = true;
        //this.pointlight.angle = Math.PI / 8.0;
        //this.pointlight.position.set(-700, 700, 5000);
        this.scene.add( this.pointlight );

        this.pointlight2 = new THREE.SpotLight( 0xffffff, 0.2 );
        this.pointlight2.position.set(0,0,20);
        //this.pointlight2.up = new THREE.Vector3(0,1,0);
        //this.pointLightHelper.up = new THREE.Vector3(0,1,0);
        this.scene.add( this.pointlight2 );
        /* const sphereSize = 1;
        this.pointLightHelper = new THREE.PointLightHelper( this.pointlight2, sphereSize, 0xff0fff );
        this.scene.add( this.pointLightHelper ); */
        //this.camera.add( this.pointlight2 );
        //this.camera.add( this.pointLightHelper );

        //Set up shadow properties for the light
        //this.pointlight.shadow.radius = 10; // default
        /* this.pointlight.shadow.mapSize.width = 2048; // default
        this.pointlight.shadow.mapSize.height = 2048; // default
        this.pointlight.shadow.camera.near = this.camera.near; // default
        this.pointlight.shadow.camera.far = this.camera.far; // default
        this.pointlight.shadow.focus = 1; // default */

        //
        
        //scene.background = new THREE.Color(0xcbe2d8);
        this.scene.background = new THREE.Color(0xffffff);  // фон container3d
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.viewWidth, this.viewHeight);
        this.renderer.premultipliedAlpha = true;
        this.container.appendChild(this.renderer.domElement);
        //
        //stats = new Stats();
        //container.appendChild( stats.dom );
        //

        this.scene.castShadow = true;

        //Create a helper for the shadow camera (optional)
        //const helper = new THREE.CameraHelper( this.pointlight.shadow.camera );
        //this.scene.add( helper );

        this.orbitControls();

        if (this.controls != null && typeof this.controls != 'undefined') {
            //this.controls.object.position.set(this.camera.x, this.camera.y, this.camera.z);
            if (this.lookpos_x != null && this.lookpos_y != null && this.lookpos_z != null){
                this.controls.target = new THREE.Vector3(this.lookpos_x, this.lookpos_y, this.lookpos_z);
            } 
        }

        //window.addEventListener('resize', onWindowResize, false);

    }

    animate =() =>{
        requestAnimationFrame(this.animate);
        this.render();
        //stats.update();
    }
    render() {
        var delta = this.clock.getDelta();
        if (this.elf !== undefined) {
            //elf.rotation.z += delta * 0.5;
            //  elf.rotation.y += delta * 0.5;
            //elf.rotation.x += delta * 0.1;
        }
        //this.pointlight.position.copy( this.camera.position );
        this.pointlight.position.set(this.camera.position.x-4, this.camera.position.y+10, this.camera.position.z);

        /* this.pointlight2.position.set(this.camera.position.x-4, this.camera.position.y, this.camera.position.z+8);
        this.pointLightHelper.position.set(this.pointlight2.position.x, this.pointlight2.position.y, this.pointlight2.position.z); */
        /* this.pointlight2.updateMatrixWorld();
        this.pointLightHelper.position.setFromMatrixPosition(this.pointlight2.matrixWorld);
        this.pointLightHelper.updateMatrix();
        this.pointLightHelper.update(); */

        this.renderer.render(this.scene, this.camera);
        if (this.controls != null && typeof this.controls != 'undefined') this.controls.update();
    }

}